import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { ActivityFeed } from 'components/activity-feed'
import { SkillActivityFeedVm } from './skill-activity-feed-vm'
import { useStore } from 'store/context'

export type SkillActivityFeedProps = {
  skillId: string
  userId: string
}

export const SkillActivityFeed = observer((props: SkillActivityFeedProps) => {
  const { skillId, userId } = props

  const { userSkills } = useStore()

  const [hasNextPage, setHasNextPage] = React.useState(false)
  const [pageNumber, setPageNumber] = React.useState(1)

  const vm = React.useMemo(() => {
    return new SkillActivityFeedVm(
      setHasNextPage,
      pageNumber,
      setPageNumber,
      skillId,
      userId
    )
  }, [setHasNextPage, pageNumber, setPageNumber, skillId, userId])

  React.useEffect(() => {
    vm.fetchActivities()
    vm.fetchUserSkills()
  }, [vm])

  return (
    <section>
      <h2 className="text-lg font-bold text-gray-900 mb-5">Updates</h2>
      <ActivityFeed
        activities={vm.filteredActivities}
        focusSkillIds={userSkills.focusedSkillIdsForUser(userId)}
        hasNextPage={hasNextPage}
        onLoadMoreClick={vm.loadNextPage.bind(vm)}
        showFocusSkillName={false}
      />
    </section>
  )
})
