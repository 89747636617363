import { store } from 'store/index'

export class SkillActivityFeedVm {
  constructor(
    private setHasNextPage: (hasNextPage: boolean) => void,
    private pageNumber: number,
    private setPageNumber: (pageNumber: number) => void,
    private skillId: string,
    private userId: string
  ) {}

  private perPage = 20

  get filteredActivities() {
    return store.activities.filteredActivities({
      skillId: this.skillId,
      userId: this.userId,
    })
  }

  async fetchActivities(pageNumber = this.pageNumber) {
    const { meta } = await store.activities.fetchAllWithAllIncludes(
      this.perPage,
      { skill_id: this.skillId, user_id: this.userId },
      pageNumber
    )

    const hasNextPage = meta?.pages && meta.pages > pageNumber
    this.setHasNextPage(!!hasNextPage)
  }

  async fetchUserSkills() {
    await store.userSkills.fetchForUser(this.userId)
  }

  async loadNextPage() {
    const nextPage = this.pageNumber + 1
    this.setPageNumber(nextPage)
    await this.fetchActivities(nextPage)
  }
}
